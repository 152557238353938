.activation{
    background-color: rgb(232, 254, 254);
    width: 100%;
    height: 100vh;
}
.activation .inner{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.activation h1{
    font-size: 1.3rem;
    font-weight: 700;
    text-align: center;
}
.activation p{
    font-size: 1.2rem;
}