a {
  text-decoration: none;
}

.btn-outline-danger {
  border: 1px solid orangered !important;
  color: orangered !important;
}

.text-danger {
  color: orangered !important;
}

.btn-primary{
  background: var(--orange) !important;
  outline: none !important;
  border: none !important;
}

.btn-primary:hover,
.bg-primary:hover{
  background: var(--orangeHover) !important;
}
.swiper {
  width: 100%;
}