.heart {
    font-size: 50px; /* Adjust size of the heart */
    color: #ccc; /* Default color */
    transition: 0.3s ease;
    cursor: pointer;
}

.active {
    color: #e63946; /* Color when liked */
}

.glow {
    animation: glowing 1.5s infinite alternate;
}

.text{
    font-size: 1.2rem;
}

@keyframes glowing {
    0% {
        text-shadow: 0 0 5px #e63946, 0 0 10px #e63946, 0 0 15px #e63946;
    }
    100% {
        text-shadow: 0 0 10px #e63946, 0 0 20px #e63946, 0 0 30px #e63946;
    }
}
@media only screen and (max-width: 998px){
    .heart {
        font-size: 25px;
    }
    .text{
        font-size: 0.875rem;
    }
}