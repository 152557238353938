
.header img {
    width: 45%;
    padding: 10px 0;
}

.header a {
    color: darkcyan;
    font-weight: 700;
}

.header form {
    width: 40%;
}

.header input {
    width: 90%;
    border-radius: 5px;
    padding: 7px;
    border: none;
    margin-right: 10px;
    border: 1px solid #aaa;
    font-size: 0.875em;
    outline: none;
}

.header form button {
    margin-top: -5px;
}

.header .navItems a {
    color: white;
}

.sticky {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 2;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.header button {
    position: relative;
}

.header button ul {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 150%;
    background-color: var(--green);
    border-radius: 10px 0;
    right: 0;
    z-index: 2;
    min-width: 200px;
}

.header button ul li {
    list-style: none;
    text-align: left;
    border-bottom: 2px solid white;
    transition: .4s;
    font-weight: 500;
}

.header button ul li:hover {
    background-color: var(--greenHover);
}

.header button ul li a,
.header button ul li:last-child {
    padding: 10px;
    display: block;
}
.phoneMenu{
    padding: 5px 0;
    position: relative;
    display: none;
}
.phoneMenu .link{
    width: 35%;
}
.phoneMenu .link img{
    width: 100%;
}
.phoneMenu div button{
    background: transparent;
    padding: 0;
    margin-right: 5px;
    font-size: 0.875rem;
}
.linked{
    font-size: 0.875rem;
}
.phoneMenu .hr{
    width: 1px;
    height: 30px;
    margin: auto 0;
    background-color: grey;
}
.phoneMenu form{
    width: 100%;
    z-index: 9;
    position: absolute;
    top: 100%;
    padding: 20px;
    background-color: white;
    border-top: 1px solid rgb(230, 228, 228);
}
.phoneMenu form input{
    width: 100%;
}
.phoneMenu form button:first-child{
    float: right;
    padding: 0;
    color: red;
}
.phoneMenu form button:last-child{
    width: 100%;
    background: teal;
    color: white;
    margin-top: 5px;
}
.header .naved {
    display: flex;
    align-items: center;
}
.mobile-menu{
    display: none;
}
@media only screen and (max-width: 998px) {
    .phoneMenu{
        display: flex;
    }
    .header .naved {
        display: none;
    }
    .mobile-menu {
        position: absolute;
        right: 0;
        top: 100%;
        width: 250px;
        height: auto;
        background-color: #fff;
        box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
        z-index: 999;
        display: flex;
        justify-content: center;
    }
    .mobile-menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
    }
    .mobile-menu ul li {
        padding: 15px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    .mobile-menu ul li a {
        color: #333;
        text-decoration: none;
        font-size: 12px;
    }
    .mobile-menu ul li a:hover {
        background-color: #f0f0f0;
    }
}
