@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,600,700,900);
@import url('https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Arvo:400,400i,700,700i);
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap');

:root {
  --green: teal;
  --greenHover: rgb(2, 102, 102);
  --orange: orangered;
  --orangeHover: rgb(210, 58, 3);
}

input,
select{
  outline: none!important;
  box-shadow: none!important;
}

input:focus,
select:focus{
  outline: none !important;
  outline-style: none;
}

.btn-success,
.bg-success{
  background: var(--green) !important;
}

.btn-success:hover,
.bg-success:hover{
  background: var(--greenHover) !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*
  font-family: "Pinyon Script", cursive;
  font-weight: 400;
*/

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700; /* Bold weight for headings */
  color: #2c3e50; /* Dark color for better readability */
  letter-spacing: 0.5px; /* Slight letter-spacing for better look */
  line-height: 1.3; /* Ensures better readability */
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}


table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
}

table td,
th {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 14px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  background-color: #04AA6D;
  background-color: teal;
  /*background-color: var(--btnBackground);*/
  color: white;
  font-weight: normal;
}

table i {
  cursor: pointer;
}

h1{
  font-size: 1.4rem;
}

h3{
  font-size: 1.3rem;
}

@media only screen and (max-width: 998px){
  p{
    font-size: 0.875rem;
  }
  h3{
    font-size: 1.125rem;
  }
}