.event-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 events per row */
    gap: 16px; /* Space between events */
    padding: 10px 0;
}

.event-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
}

.event-cover {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.event-title {
    font-size: 1.5em;
    margin: 8px 0;
}

.event-description {
    font-size: 1em;
    color: #666;
}

.event-location,
.event-price {
    font-size: 0.9em;
    color: #333;
}

.event-location{
    position: absolute;
    bottom: 0;
    padding: 10px;
    left: 0;
    background-color: #333;
    color: white;
}

.event-status {
    display: inline-block;
    margin-top: 8px;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.8em;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.completed {
    background-color: #d4edda;
    color: #155724;
}

.not-completed {
    background-color: #f8d7da;
    color: #721c24;
}

.event-actions {
    margin-top: 12px;
    display: flex;
}

.event-actions button {
    margin: 0 5px;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
    flex: 45%;
}

.event-card h3{
    font-size: 1.2rem;
}

@media only screen and (max-width: 998px){
    .event-list {
        grid-template-columns: repeat(1, 1fr);
    }
}