.side{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: sticky;
    top: 5%;
}
.categories a{
    background: #6c757d;
    padding: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: background-color 0.3s ease, color 0.3s ease;
    width: 45%;
    min-height: 55px;
    align-items: center;
    /*background-color: #fff3f3;*/
}
.categories a::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 0;
    transition: transform 0.3s ease;
    transform: scaleX(0);
    transform-origin: left;
}
.categories a:hover::before {
    transform: scaleX(1);
    transform-origin: right;
  }
.categories a:hover{
    box-shadow: 2px 2px rgb(243, 144, 108);
    background-color: #495057;
    color: #e9ecef;
}
.slidder img{
    height: 300px;
    border-radius: 5px;
    object-fit: cover;
    object-position: top;
}
.cardImageDiv{
    width: 100%;
    overflow: hidden;
}
.cardDiv{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}
.card{
    width: 100%;
}
.card .card-text a{
    color: darkcyan;
    font-size: 0.875rem;
}
.card-body{
    position: relative;
}
.card img{
    height: 250px;
    object-fit: cover;
    cursor: pointer;
}
.card .actionsBtns button:nth-child(1),
.card .actionsBtns button:nth-child(2){
    background-color: none !important;
    background: none !important;
    color: black;
    border: none !important;
}   

.filterEvents li{
    background: white;
    color: black;
    transition: all 0.5s ease-in-out;
    padding: 10px;
    /*border-radius: 8px;
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
    transform: scale(1);
}

.filterEvents li:hover {
    cursor: pointer;
    background: linear-gradient(to right, #808080, darkcyan); /* Smooth gray to green gradient */
    color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
    transform: scale(1.03); /* Small scale up effect */
}

/* Disable hover effect for the first li */
.filterEvents li:first-child {
    pointer-events: none; /* Disables hover and clicking */
    background: white; /* Ensures the background doesn't change */
    color: black; /* Keeps the text color as black */
    box-shadow: none; /* No shadow for first li */
    transform: none; /* No scaling for first li */
}

.share-buttons a {
    font-size: 0.8rem;
    color: #fff;
    transition: all 0.3s ease;
}

.share-buttons a:hover {
    color: #ddd;
}

.btn-whatsapp {
    background-color: #25d366;
}

.btn-twitter {
    background-color: #1da1f2;
}

.btn-linkedin {
    background-color: #0077b5;
}
.share-buttons{
    position: absolute;
    bottom: 80px;
    right: 10px;
    display: flex;
    flex-direction: column;
    background: white;
    /*border: 1px solid #aaa;
    border-radius: 5px;*/
}

/* Card Image Hover Animation */
.card-img-top {
    transition: transform 0.7s ease, box-shadow 0.4s ease;
}

.card-img-top:hover {
    transform: scale(1.1) rotate(2deg); /* Slight zoom effect */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Adds depth */
}

.positioned{
    position: fixed;
    width: 25%;
    top: 15%;
    z-index: 9;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
}

@media only screen and (max-width: 998px){
    .positioned{
        width: 100%;
    }
    .cardDiv{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }
    .card .actionsBtns button span{
        font-size: 0.875rem;
    }
}