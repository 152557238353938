.selectors {
    color: gray;
}
.select{
    color: orangered;
}

.selectors:hover {
    cursor: pointer;
    color: darkcyan;
}

.min {
    width: 40%;
    margin: auto;
}

.min img {
    width: 100%;
}

.min-divided {
    display: flex;
    padding: 0 15%;
    gap: 2%;
}

/* Styles for AuthRequiredMessage Component */
.auth-required-container {
    display: flex;
    height: 70vh;
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 90%;
    margin: auto;
}

.auth-required-image {
    /*  flex: 1; */
    width: 50%;
    background-color: #f4f4f4;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.auth-required-image::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, transparent, black);
    border-radius: 20px;
}

.auth-required-image .overlay {
    position: absolute;
    bottom: 10px;
    padding: 20px 60px;
}

.auth-required-image .overlay h1 {
    color: white;
}

.auth-required-image .overlay p {
    color: white;
}

.fa-triangle-exclamation {
    font-size: 3rem;
    color: red;
    margin-bottom: 20px;
}

.auth-required-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.auth-required-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #ffffff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 45%;
    z-index: 1;
    border-radius: 10px;
}

.auth-required-text h1 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
}

.auth-required-text p {
    font-size: 1.2rem;
    text-align: center;
    color: #666;
    font-weight: 200;
}

.auth-required-text a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.auth-required-text a:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 998px) {
    .min {
        width: 95%;
        margin: auto;
    }
    .auth-required-container {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
    }

    .auth-required-image {
        width: 100%;
        height: 70vh;
    }

    .auth-required-image .overlay {
        position: absolute;
        bottom: 10px;
        padding: 10px 30px;
    }

    .auth-required-image .overlay h1 {
        color: white;
        font-size: 1.4rem;
    }

    .auth-required-container {
        height: auto;
    }
    .auth-required-text {
        padding: 20px;
        position: static;
        top: unset;
        transform: unset;
        left: unset;
        margin-bottom: 20px;
    }
    .auth-required-text h1{
        font-size: 1.2rem;
    }

    .fa-triangle-exclamation {
        font-size: 1.5rem;
    }

    .auth-required-text p {
        font-size: 1rem;
        text-align: center;
        color: #666;
        font-weight: 200;
    }
    .mainNav a span{
        display: none;
    }
    .select{
        background-color: orangered;
        color: white;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .selectors:hover {
        cursor: pointer;
        color: white;
    }
    
}