.change-password-container {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
}

.change-password-container input{
    outline: none;
}

.change-password-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.error-message {
    color: red;
    margin-top: 10px;
}

.change-password-container h3{
    font-size: 1.2rem;
}