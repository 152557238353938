.regDiv {
    /*background: url("../../public/images/register.jpg");
    background-size: cover;
    background-attachment: fixed;*/
    height: 100%;
    padding: 20px;
    position: relative;
}

/*
.regDiv::before{
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, transparent, black);
}
*/
.register {
    width: 30%;
    margin: auto;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid rgb(205, 205, 205);
    background-color: #fff;
    position: relative;
    z-index: 1;
}

.register a {
    text-decoration: none;
}

.register label {
    font-weight: bold;
    color: grey;
}

.regDiv .text-success {
    color: grey !important;
}

.success-container {
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 40%;
    margin: 50px auto;
}

.success-container h1 {
    color: teal;
}

.success-container p {
    font-size: 18px;
}

.success-container a {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: teal;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.success-container a:hover {
    background-color: var(--greenHover);
}

.success-container span{
    font-size: 5rem;
    color: var(--green);
}


@media only screen and (max-width: 998px) {
    .register {
        width: 100%;
        margin: 0 auto;
        margin-top: 2.5%;
        margin-bottom: 2.5%;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid rgb(205, 205, 205);
        background-color: #fff;
        position: relative;
        z-index: 1;
    }
    .success-container {
        text-align: center;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        padding: 20px;
        width: 100%;
        margin: 50px auto;
    }
    .success-container p {
        font-size: 0.875rem;
    }
    .success-container span{
        font-size: 4rem;
    }
}