.video-player-container {
    display: flex;
    gap: 5%;
    padding: 20px;
}

.main-video {
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: start;
    position: sticky;
    top: 125px;
    max-height: 400px;
}

.main-video-element {
    width: 100%;
    max-height: 350px;
    border-radius: 8px;
    object-fit: cover;

}

.main-video-info {
    padding-top: 10px;
}

.video-list {
    flex: 1;
}

.video-thumbnails {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.video-thumbnail {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    cursor: pointer;
}

.thumbnail-image {
    width: 100%;
    max-height: 200px;
    border-radius: 8px;
}

.thumbnail-title {
    width: 100%;
    font-size: 0.9rem;
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 998px){
    .video-player-container{
        display: block;
    }
    .main-video-element {
        max-height: auto;
    }
    .main-video {
        position: static;
        max-height: auto;
        max-height: unset;
    }
    .main-video h4{
        font-size: 1.2rem;
    }
    .video-list h4{
        font-size: 1.2rem;
    }
}