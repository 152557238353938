
.search-input {
    position: relative;
    display: flex;
    align-items: center;
}
  
.search-input input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    width: 100%;
}
  
.search-input .search-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
    background-color: #333;
    border-radius: 4px;
    padding: 8px;
    color: white;
}
.helpHeader{
    width: 100%;
    padding: 50px 20px;
    background: url('../../public/images/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
}
.helpHeader form{
    width: 40%;
    margin: auto;
}
.helpHeader label{
    color: #333;
    font-weight: 700;
    text-align: center;
    display: block;
    padding-bottom: 10px;
}
.actions{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 70px 20%;
}
.actions .div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2%;
    border: 1px solid green;
    padding: 10px;
}
.actions .div h2{
    font-size: 1rem;
    font-weight: 700;
}
.actions .div p{
    font-size: 0.9rem;
}
.actions .div div:first-child{
    width: 20%;
    text-align: center;
}
@media only screen and (max-width: 998px){
    .helpHeader{
        padding: 25px 20px;
    }
    .helpHeader form{
        width: 90%;
        margin: auto;
    }
    .actions{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        padding: 15px 5%;
    }
}