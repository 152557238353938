.eventsRegistered{
    width: 60%;
}
.eventsRegisteredLast{
    width: 40%;
}
.eventsRegistered div{
    flex-basis: 48%;
    flex-grow: 1;
    background-color: whitesmoke;
}
.eventsRegistered .evMain:hover{
    border: 2px solid rgb(222, 57, 57);
    cursor: pointer;
}
.eventsRegistered img{
    width: 50%;
    height: 150px;
    object-fit: cover;
}
.eventsRegistered .evMain{
    position: relative;
    border: 2px solid white;
    transition: .4s;
}
.eventsRegistered .evMain p{
    font-size: 0.875rem;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
    background-color: #fff;
}
.revenue h4{
    border-left: 5px solid teal;
    padding-left: 10px;
    margin-bottom: 20px;
}
@media only screen and (max-width: 998px){
    .rev{
        flex-direction: column-reverse;
        flex-wrap: wrap;
    }
    .eventsRegistered{
        width: 100%;
    }
    .eventsRegisteredLast{
        width: 100%;
    }
    .h5{
        background: radial-gradient(circle, rgba(0, 128, 128, 0.5), teal);
        border-radius: 10px;
        padding: 20px;
        color: white;
        font-size: 1rem;
    }
    .eventsRegistered img{
        width: 40%;
        height: auto;
    }
    .eventsRegistered p:last-child{
        padding: 5px;
    }
}