.faq-container {
  max-width: 100%;
  margin: auto;
  padding: 20px;
}

.faq-title {
  font-size: 1.3rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 700; /* Bold weight for headings */
  color: #2c3e50; /* Dark color for better readability */
  letter-spacing: 0.5px; /* Slight letter-spacing for better look */
  line-height: 1.3; /* Ensures better readability */
}

.faq-item {
  margin-bottom: 10px;
  border-bottom: 1px solid #efefef;
  padding: 10px 0;
}

.qtn{
  color: rgb(255, 136, 92);
}
.faq-question {
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  color: teal;
  display: flex;
  gap: 10px;
  align-items: center;
}

.faq-answer {
  font-size: 1rem;
  margin-top: 10px;
  color: #555;
}