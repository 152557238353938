.narrow{
    padding: 3.5% 10%;
}
.actives{
    border: 2px solid rgb(252, 25, 9);
    color: rgb(252, 25, 9);
}
.selected{
    border: 2px solid darkcyan;
    height: 70px;
    padding: 10px;
    border-radius: 5px;
    color: darkcyan;
    transition: 1s;
}
.horizontal{
    display: grid;
    grid-gap: 10px;
    padding: 10px;
    grid-template-columns: repeat(auto-fill,minmax(70px,1fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(70px,1fr);
    overflow-x: auto;
    justify-content: left;
    align-items: center;
}
.horizontal div{
    padding: 10px;
    border-radius: 5px;
    height: 70px;
}
.horizontal div:hover{
    cursor: pointer;
    color: rgb(2, 80, 80);
    border: 2px solid rgb(2, 80, 80);
}
.detailed p{
    margin-bottom: 5px;
}
.narrow img{
    width: 100%;
    height: 250px;
}
@media only screen and (max-width: 998px){
    .narrow{
        padding: 3.5% 5%;
    }
}