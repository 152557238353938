/* NotFoundPage.css */

.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

.not-found-container h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.not-found-container p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.not-found-container a {
    color: #007bff;
    /* Bootstrap blue, change as needed */
    text-decoration: none;
    font-size: 1.2rem;
}

.not-found-container a:hover {
    text-decoration: underline;
}