/* Slider container, latest modified code */

.slider-container {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;
}

.slides-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    transition: transform 0.5s ease-in-out;
}

.slid {
    flex: 1;
    position: relative;
}

.nav-buttons {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}

button {
    /*background-color: rgba(0, 0, 0, 0.5);*/
    background-color: transparent;
    color: black;
    border: none;
    padding: 10px;
    font-size: 24px;
    cursor: pointer;
    border-radius: 50%;

}

button:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.prev-button {
    left: 10px;
}

.next-button {
    right: 10px;
}