
.adminActions{
    padding: 2.5% 0;
    position: relative;
    display: grid;
    grid-template-columns: 0.25fr 0.65fr;
    align-items: start;
    gap: 50px;
}
.adminActions .aside{
    padding: 20px;
    max-height: 75vh;
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    position: sticky;
    top: 25%;
}
.adminActions table{
    width: 100%;
}
.adminActions form input,
.adminActions form textarea{
    border: none;
    border: 1px solid #aaa;
    border-radius: 5px;
    padding: 5px;
    min-width: 50%;
    outline: none;

}
.adminActions ul{
    width: 100%;
    padding: 0;
    margin: 0;
}
.adminActions ul li{
    list-style: none;
    padding: 10px;
    color: #333;
    margin-bottom: 5px;
    transition: .4s;
    display: flex;
    gap: 30px;
    align-items: center;
}
.adminActions ul li:hover{
    background-color: rgb(211, 229, 245);
    color: #333;
    cursor: pointer;
}
@media only screen and (max-width: 998px){
    .adminActions{
        grid-template-columns: 1fr;
    }
    .adminActions .aside{
        max-height: 50vh;
        position: unset;
    }
    .adminActions ul li{
        padding: 10px;
        gap: 10px;
        font-size: 0.9rem;
    }
    .adminActions ul li span{
        color: grey;
        font-size: 18px;
    }
}