#loginForm{
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
}
#loginForm form{
    width: 30%;
    margin: 20px auto;
    /*
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    */
}
#loginForm .main{
    /*background: url("{% static 'images/bg.jpg'%}");*/
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
#loginForm .main::before{
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.5));
}
#loginForm .main div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    color: white;
}
#loginForm input{
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin-bottom: 10px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#loginForm .errorlist{
    list-style: none;
    background-color: white;
    color: red;
    padding: 5px;
    border-radius: 5px;
}
#loginForm ul{
    padding: 0;
    margin: 0;
}
#loginForm ul li{
    list-style: none;
    color: grey;
    font-size: 0.9rem;
}
#loginForm button{
    background: darkcyan;
    border-radius: 5px;
    padding: 5px;
    color: white;
    border: none;
    width: 100%;
}

/* floating label input */
.floating-label {
    position: relative;
}

.floating-label input {
    width: 100%;
    padding: 12px 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    transition: all 0.3s ease-in-out;
}

.floating-label label {
    position: absolute;
    left: 0.75rem;
    top: 0.45rem;
    font-size: 16px;
    color: #888;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
}

/* When the input is focused, move the label above */
.floating-label input:focus {
    border-color: teal;
    outline: none;
}

.floating-label input:focus + label,
.floating-label input:not(:placeholder-shown) + label {
    top: -10px;
    font-size: 12px;
    color: teal;
        background-color: white;
}

/* Hide the placeholder text when not needed */
.floating-label input:focus::placeholder,
.floating-label input:not(:placeholder-shown)::placeholder {
    visibility: hidden;
}
@media only screen and (max-width: 998px){
    #loginForm{
        height: auto;
    }
    #loginForm form{
        margin: 20px auto;
        width: 90%;
        /*
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        */
    }
}