.PaymentDetailsForm {
    padding: 20px 20px;
}

.PaymentDetailsForm label {
    width: 15%;
}

.PaymentDetailsForm input,
.PaymentDetailsForm select {
    flex-grow: 1;
    outline: none;
    border: none;
    border: 1px solid #aaa;
    border-radius: 5px;
    padding: 8px;
    outline: none;
}

.PaymentDetailsForm input:focus,
.PaymentDetailsForm select:focus {
    background-color: lightseagreen;
    color: white;
}

.PaymentDetailsForm input:focus::placeholder,
.PaymentDetailsForm select:focus::placeholder {
    color: white;
}

.PaymentDetailsForm h3 {
    font-size: 1.2rem;
    border-left: 10px solid teal;
    padding-left: 10px;
    margin-bottom: 30px;
}

.PaymentDetailsForm label {
    color: teal;
}

.PaymentDetailsForm button {
    padding: 10px 30px;
    font-size: 17px;
    border-radius: 5px;
    background: teal;
    color: white;
    border: none;
    transition: .4s;
}

.PaymentDetailsForm button:hover {
    background: rgb(4, 80, 80);
}

#cancelBtn {
    background: rgb(238, 54, 54);
}

#cancelBtn:hover {
    background: rgb(147, 28, 28);
}

@media only screen and (max-width: 998px) {
    .PaymentDetailsForm label {
        width: 100%;
    }

    .PaymentDetailsForm input,
    .PaymentDetailsForm select {
        width: 100%;
    }
    .PaymentDetailsForm select{
        padding: 10px;
    }
}