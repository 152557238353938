.slider {
    position: relative;
    width: 95%;
    margin: auto;
    /*overflow: hidden;*/
    display: flex;
    align-items: center;
    justify-content: center;
}

.slides-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
    transition: transform 0.5s ease-in-out;
}

.slide {
    flex-shrink: 0;
    text-align: center;
    transition: transform 0.3s ease-in-out;
}

.slide.large {
    width: 50%;
    position: relative;
}

.slide.small {
    width: 25%;
    position: relative;
}

.slide.large::before,
.slide.slide.small::before{
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.5));
}

.slider img {
    width: 100%;
    height: 400px;
    display: block;
    object-fit: cover;
    border-radius: 10px;
}
.slide.small img{
    object-position: center;
}

.caption {
    position: absolute;
    bottom: 20px;
    left: 5%;
    right: 5%;
    text-align: left;
}
.caption h3{
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 700; 
    line-height: 1.3;
    color: white;
}
.large h3{
    font-size: 2rem;
}
.caption p{
    color: white;
}

button.prev,
button.next {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 0;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.prev:hover,
button.next:hover{
    background: var(--greenHover);
}

button.prev {
    width: 30px;
    height: 30px;
}

button.next {
    width: 30px;
    height: 30px;
}
@media only screen and (max-width: 998px) {
    .slides-container {
        overflow: hidden;
    }
    .slide.large {
        width: 100%;
    }

    .slide.small {
        width: 100%;
    }
    
    .slider {
        width: 100%;
    }

    .slider .prev, .slider .next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    
    .slider .prev {
        left: 10px;
    }
    
    .slider .next {
        right: 10px;
    }
    
    .slider button:hover {
        background: var(--greenHover);
    }
    
}
