.forgotPassword{
    width: 30%;
    margin: auto;
    position:fixed;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
    border: 1px solid rgb(220, 219, 219);
    padding: 20px;
    border-radius: 10px;
}
@media only screen and (max-width: 998px){
    .forgotPassword{
        width: 90%;
    }
}