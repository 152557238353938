.four{
    display: flex;
    flex-wrap: wrap;
    gap: 1%;
}
.four .div{
    width: 24%;
    cursor: pointer;
}
.four img{
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.howItWorks h1{
    font-size: 1.4rem;
}
@media only screen and (max-width: 998px){
    .four .div{
        width: 100%;
        cursor: pointer;
    }
    .four img{
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .howItWorks{
        padding: 0 10%;
    }

    .howItWorks h1{
        font-size: 1.2rem;
    }
}