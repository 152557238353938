.detailsInner{
    position: relative;
    margin: -70px auto;
    background-color: #fff;
    border-radius: 20px 20px 0 0;
    padding: 50px 20px;
}
.detailsIntroduction{
    position: relative;
}
.detailsIntroduction .likeButton{
    position: absolute;
    top: 8%;
    left: 5%;
}
.detailsIntroduction div{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));
    border-radius: 20px 0 0 20px;
    padding: 20px 40px;
    max-width: 30%;
    border-bottom: 5px solid darkcyan;
}
.detailsIntroduction div h3{
    color: white;
    font-size: 1.5rem;
}
.detailsIntroduction div p{
    color: white;
}
.detailsIntroduction div p:first-child{
    text-shadow: 2px 2px darkcyan;
}
.details img{
    height: 60vh;
    object-fit: cover;
    object-position: center;
}
.details .sticky{
    width: 100%;
    margin: auto;
    box-shadow: 0 2px 5px 0 rgb(72 72 72 / 30%);
    background-color: #fff;
    border-radius: 6px;
    padding: 40px 20px;
    position: sticky;
    top: 25%;
    z-index: 1;
}
.details .sticky a{
    background-color: #fff;
    color: #06363d;
    border: 2px solid #06363d;
    border-radius: 50px;
    padding: 10px 20px;
    font-weight: 700;
    min-width: 200px;
}
.details .sticky h1{
    font-weight: 800;
}
.details .sticky .icon{
    color: #06363d;
    padding-right: 10px;
}

.details h2{
    font-weight: 500;
    padding: 5px 10px;
    border-left: 5px solid darkcyan;
    padding-left: 20px
}
.details a{
    text-decoration: none;
}
.details a i{
    font-size: 30px;
    padding-right: 10px;
}
.margined{
    margin-top: 70px;
}

.publish{
    position: fixed;
    bottom: 10%;
    right: 5%;
    z-index: 1;
}

.publish button{
    padding: 15px 20px;
    border-radius: 20px;
    background-color: teal;
    border: none;
    border-bottom: 1px solid orangered;
    color: white;
    font-size: 0.875rem;
}

.purchaseTicket{
    display: none;
}

.purchaseTicket button{
    padding: 15px 20px;
    border-radius: 30px;
    background-color: teal;
    border: none;
    border-bottom: 1px solid orangered;
    color: white;
    font-size: 0.875rem;
}
/* swipper styles */
.swiper .swiper-button-prev, .swiper .swiper-button-next {
    color: #333; /* Change icon color */
    background-color: #f0f0f0; /* Light grey background */
    font-weight: bold;
    width: 30px !important; /* Adjust button size */
    height: 30px !important; /* Adjust button size */
    border-radius: 50%; /* Keep buttons circular */
    font-size: 18px !important; /* Adjust icon size */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
}

.swiper-button-next::after, .swiper-button-prev::after {
    font-size: 14px !important; /* Icon size */
}

/*
.swiper-button-prev:after, .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
}
*/
@media only screen and (max-width: 998px){
    .detailsInner{
        padding: 30px 10px;
    }
    .purchaseTicket{
        display: block;
        position: fixed;
        bottom: 5%;
        right: 2.5%;
    }
    .details img{
        height: 50vh;
    }
    .detailsIntroduction div{
        border-radius: 10px 0 0 10px;
        padding: 10px 20px;
        max-width: 80%;
        border-bottom: 2.5px solid darkcyan;
    }
    .detailsIntroduction div h3{
        color: white;
        font-size: 1.2rem;
    }
    .details .sticky{
        margin: 20px auto;
        padding: 20px 20px;
        position: static;
    }
    .switched{
        display: flex;
        flex-direction: column-reverse;
    }
    .margined{
        margin-top: 80px;
    }
    .details{
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
    }
    .detailsInner{
        border-radius: 20px 20px 20px 20px;
    }
    .swiper-button-next::after, .swiper-button-prev::after {
        font-size: 14px !important; /* Icon size */
    }
}