.contact {
    padding: 2.5% 5%;
    display: flex;
    gap: 20px;
    align-items: center;
    background-attachment: fixed;
    background-size: cover;
    position: relative;
    background-color: whitesmoke;
}

.contact span {
    display: block;
    color: rgb(40, 38, 38);
    font-size: 0.875rem;
}

.contact form {
    width: 50%;
    position: relative;
    z-index: 1;
}

.contact .div {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
}

.contact label {
    display: block;
    color:rgb(77, 72, 72);
    padding: 8px 0;
}

.contact .div input,
.contact textarea {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.contact textarea {
    height: 120px;
}

.contact button {
    padding: 10px;
}

.contact .div input:focus,
.contact textarea:focus {
    outline: none;
    border: 2px solid orange;
    background: rgb(247, 246, 246);
}

@media only screen and (max-width: 998px) {
    .contact{
        display: block;
    }
    .contact .div {
        display: grid;
        gap: 5px;
        grid-template-columns: repeat(1, 1fr);
    }

    .contact label {
        display: block;
        padding: 5px 0;
    }

    .contact form {
        width: 100%;
        position: relative;
        z-index: 1;
    }

    .contact .div input,
    .contact textarea {
        width: 100%;
        padding: 7px;
    }

    .contact textarea {
        height: 120px;
    }

    .contact h4,
    .contact h5{
        font-size: 1.2rem;
    }

    .addressDiv{
        margin-top: 30px;
    }

}