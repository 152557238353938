.navigator{
    justify-content: center;
    background: #4e4d4d;
    position: sticky;
    bottom: 0;
    z-index: 1;
    width: 100%;
    overflow: auto;
}
.navigator a{
    color: white;
    padding: 10px;
}
.navigator span{
    font-size: 0.875rem;
    display: block;
    color: whitesmoke;
}
@media only screen and (max-width: 998px){
    .navigator{
        justify-content: flex-start;
    }
    .navigator a{
        color: white;
        padding: 10px;
        font-size: 0.975rem;
    }
    .navigator span{
        font-size: 0.775rem;
        display: block;
        color: whitesmoke;
    }
}