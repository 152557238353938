/*.profile-container {}*/

.profile-container .profileData {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2%;
    padding: 10px 5%;
}

.profile-container h2 {
    color: #333;
    padding: 20px 5% 10px 5%;
    font-size: 1.2rem;
}

.loading {
    text-align: center;
    font-size: 18px;
    color: #888;
}

.profile-form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.profile-container label {
    font-weight: bold;
    margin-bottom: 5px;
}

.profile-container input,
.profile-container select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
}

.profile-container input[type="file"] {
    padding: 3px;
}

.button-group {
    display: flex;
    justify-content: space-between;
}

/*
.profile-container .btn {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}
*/

.profile-container .btn:hover {
    background-color: #0056b3;
}

.profile-container .profile-details {
    display: flex;
    align-items: center;
    gap: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 20px;
}

.profile-container .profile-pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.profile-container .details {
    flex-grow: 1;
}

.profile-container .details p {
    display: flex;
    margin: 5px 0;
    padding: 5px 0;
    border-bottom: 1px solid rgb(235, 235, 235);
}

.profile-container .details p strong {
    width: 30%;
}


.security-advice {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
}

.security-advice h3 {
    margin-bottom: 15px;
    font-size: 1.2rem;
}

.security-advice ul {
    list-style-type: disc;
    padding-left: 20px;
}

.security-advice li {
    margin-bottom: 10px;
}

.profile-container .profile-details .left{
    width: 200px;
}

@media only screen and (max-width: 998px){
    .profile-container .profileData {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1%;
        padding: 10px 2.5%;
        width: 100%;
    }
    .profile-container .profile-details {
        display: block;
    }
    .profile-container .profile-details .left{
        width: 100%;
    }
    .profile-container .details {
        flex-grow: unset;
    }
    .profile-form input{
        width: 100%;
    }
}